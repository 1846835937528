<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        type="primary"
        class="back-btn"
        @click="
          $router.push({
            name: 'activity'
          })
        "
      >
        <i class="t-transition icons icon-chevron-left" /> Назад
      </a-button>
      <a-row class="post-search" type="flex">
        <a-select
          v-model="perPage"
          class="table__parpage"
          option-filter-prop="children"
          placeholder="Все"
          @change="tableperPage"
        >
          <a-select-option
            v-for="(limit, index) in LimitList"
            :key="index"
            :value="limit.value"
          >
            {{ limit.title }}
          </a-select-option>
        </a-select>
        <a-button
          type="primary"
          class="all-news-btn"
          style="margin: 0 15px"
          @click="Allnews"
        >
          Все
        </a-button>
        <a-button type="primary" @click="createFunc()">
          <a-icon type="plus" />{{ $t("Add") }}
        </a-button>
      </a-row>
      <a-row type="flex" style="width: 100%; justify-content: space-between">
        <h1>{{ headTitle[$route.params.type].title[$i18n.locale] }}</h1>
        <a-button
          :disabled="cardsIsEmpty"
          type="danger"
          @click="removeItem(selectedPosts)"
        >
          <a-icon class="action-btns" type="delete" />
          <!--{{ $t("Delete") }}-->
        </a-button>
      </a-row>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="categoryProps.results || []"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 3500, y: 600 }"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: 'headquarters-update',
              params: { id: item.id }
            })
          "
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="activity_title" slot-scope="item">
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: 'headquarters-update',
              params: { id: item.id }
            })
          "
        >
          <b>{{ item.title || "-" }}</b>
        </span>
      </template>

      <template slot="photo" slot-scope="item">
        <!--<spinner v-if="loading" />-->
        <div v-if="!item.photo">no photo</div>
        <div v-else>
          <img
            style="width: 80px; height: 80px; object-fit: cover"
            alt="profile_img"
            :src="item.photo"
          />
        </div>
      </template>

      <template slot="link" slot-scope="item">
        <a :href="item.url" target="_blank">{{ item.url }}</a>
      </template>

      <template slot="order" slot-scope="item">
        <a-input-number
          v-model="item.order"
          style="width: 70px"
          :min="1"
          @pressEnter="updateLevel(item.id, $event)"
        />
      </template>

      <template slot="author" slot-scope="item">
        {{ item.author || "-" }}
      </template>

      <template slot="flayers" slot-scope="item">
        <a-button
          type="primary"
          style="width: 90%; background-color: #26a6de"
          @click="
            $router.push({
              name: 'flayers',
              params: { act_id: item.id, type: $route.params.type }
            })
          "
        >
          {{ $t("Add") }}
        </a-button>
      </template>

      <template slot="departments" slot-scope="item">
        <a-button
          type="primary"
          style="width: 90%; background-color: #26a6de"
          @click="
            $router.push({
              name: 'departments',
              params: { act_id: item.id, type: $route.params.type }
            })
          "
        >
          {{ $t("Add") }}
        </a-button>
      </template>

      <template slot="about" slot-scope="item">
        <a-button
          type="primary"
          style="width: 90%; background-color: #26a6de"
          @click="
            $router.push({
              name: 'aboutAct',
              params: { act_id: item.id, type: $route.params.type }
            })
          "
        >
          {{ $t("Add") }}
        </a-button>
      </template>

      <template slot="doc" slot-scope="item">
        <a-button
          type="primary"
          style="width: 90%; background-color: #26a6de"
          @click="
            $router.push({
              name: 'doc',
              params: { act_id: item.id, type: $route.params.type }
            })
          "
        >
          {{ $t("Add") }}
        </a-button>
      </template>

      <template slot="status" slot-scope="item">
        <!-- <osg-image :img="item" /> -->
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="to_slider" slot-scope="item">
        <a-badge
          :status="item.on_slider ? 'success' : 'error'"
          :text="item.on_slider ? 'Да' : 'Нет'"
        />
      </template>

      <template slot="is_blog" slot-scope="item">
        <a-badge
          :status="item.is_blog ? 'success' : 'error'"
          :text="item.is_blog ? 'Да' : 'Нет'"
        />
      </template>

      <template slot="sub_content" slot-scope="item">
        <a-button
          style="margin-right: 10px"
          type="primary"
          @click="
            $router.push({
              name: 'faq-ques',
              params: {
                theme_id: item.id
              }
            })
          "
        >
          Добавить
        </a-button>
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push({
              name: 'headquarters-update',
              params: { id: item.id }
            })
          "
        >
          <!--{{ $t("Edit") }}-->
          <a-icon class="action-btns" type="edit" />
        </a-button>
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item.id)"
        >
          <a-button style="margin-right: 10px" type="danger">
            <a-icon class="action-btns" type="delete" />
            <!--{{ $t("Delete") }}-->
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="perPage"
        :current="page"
        :default-page-size="perPage"
        :total="+categoryProps.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      selectedRowKeys: [],
      selectedPosts: [],
      columns: [
        {
          title: "ID",
          key: "id",
          width: "90px",
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("Order"),
          key: "order",
          width: "90px",
          fixed: "left",
          scopedSlots: { customRender: "order" }
        },
        {
          title: this.$t("TablePhoto"),
          key: "photo",
          width: "100px",
          fixed: "left",
          scopedSlots: { customRender: "photo" }
        },
        {
          title: this.$t("TableTitle"),
          key: "activity_title",
          width: "300px",
          fixed: "left",
          scopedSlots: { customRender: "activity_title" }
        },
        {
          title: "Флайеры",
          key: "flayers",
          width: "15%",
          scopedSlots: { customRender: "flayers" }
        },
        {
          title: "Информация",
          key: "about",
          width: "15%",
          scopedSlots: { customRender: "about" }
        },
        {
          title: "(TABS) По годам",
          key: "doc",
          width: "15%",
          scopedSlots: { customRender: "doc" }
        },
        {
          title: "Отделы",
          key: "departments",
          width: "15%",
          scopedSlots: { customRender: "departments" }
        },
        {
          title: this.$t("Link"),
          key: "link",
          width: "40%",
          scopedSlots: { customRender: "link" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: "20%",
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("OnSlider"),
          key: "to_slider",
          width: "20%",
          scopedSlots: { customRender: "to_slider" }
        },
        {
          title: "На блок",
          key: "is_blog",
          width: "20%",
          scopedSlots: { customRender: "is_blog" }
        },
        {
          title: this.$t("Author"),
          key: "author",
          width: "20%",
          scopedSlots: { customRender: "author" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "20%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: "20%",
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          // width: "100px",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      headTitle: [
        {
          title: {
            oz: "Марказий аппарат",
            uz: "Markaziy apparat",
            qr: "Центральный аппарат",
            ru: "Центральный аппарат",
            en: "Headquarters"
          }
        },
        {
          title: {
            oz: "Вазирлик тассаруфидаги ташкилотлар",
            uz: "Vazirlik tassarufidagi tashkilotlar",
            qr: "Подведомственные организации",
            ru: "Подведомственные организации",
            en: "Subordinated organizations"
          }
        }
      ],
      search: "",
      categoryProps: {
        results: [],
        count: 0
      },
      LimitList: [
        {
          value: 10,
          title: "10"
        },
        {
          value: 20,
          title: "20"
        },
        {
          value: 40,
          title: "40"
        }
      ],
      cardsIsEmpty: true,
      perPage: 20,
      page: 1,
      offset: 0
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    async updateLevel(itemId, event) {
      try {
        await this.$api.patch(`/admin/common/activity/${itemId}/update/`, {
          order: event.target.value
        })
        this.$message.success("Позиция обновлена")
        await this.fetchData()
      } catch (error) {
        console.error(error)
      }
    },
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
    },
    tableperPage() {
      this.offset = 0
      this.fetchData()
    },
    async Allnews() {
      this.loading = true
      this.perPage = null
      this.categoryProps = {
        results: [],
        count: 0
      }
      try {
        const r = await this.$store.dispatch("activity/fetch", {
          type: this.$route.params.type
        })
        if (r) {
          this.$set(this, "categoryProps", {
            results: r || []
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
      this.loading = false
    },
    toPage(arg) {
      this.$router.push({ query: { page: arg } })
    },
    SearchInput() {
      this.offset = 0
      this.fetchData()
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.perPage
      try {
        const r = await this.$store.dispatch("activity/fetch", {
          limit: this.perPage,
          offset: this.offset,
          type: this.$route.params.type,
          root: true
        })
        if (r) {
          this.$set(this, "categoryProps", {
            results: r.results || [],
            count: r.count
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
      this.loading = false
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const res = await this.$store.dispatch("activity/deletePost", i)
            if (res && res.status === 204) {
              this.$message.success("Новости Успешно удалено")
            }
          } catch (e) {
            this.$sentry.captureMessage(e)
          }
        }
      } else {
        try {
          const res = await this.$store.dispatch("activity/deletePost", id)
          if (res && res.status === 204) {
            this.$message.success("Новости Успешно удалено")
          }
        } catch (e) {
          this.$sentry.captureMessage(e)
        }
      }
      await this.fetchData()
      this.selectedCards = []
      this.cardsIsEmpty = true
    },
    async createFunc() {
      await this.$router.push({
        name: "headquarters-create",
        params: {
          ...this.$route.params
        }
      })
    }
  }
}
</script>
